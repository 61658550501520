<template>
  <div>
    <CCard class="col-md-12 p-2">
      <CRow class="p-3">
        <h4 v-if="edit" class="color-light ml-2 page_title"><span class="ul_text">EDI</span>T CAMPAIGN</h4> 
        <h4 v-else class="color-light ml-2 page_title"><span class="ul_text">CRE</span>ATE CAMPAIGN</h4> 
        <div class="card-header-actions float-right" @click="openModel()">
          <small class="text-white ml-2"><CIcon name="cil-info" /></small>
        </div>
      </CRow>
      
      <CRow class="col-md-10">
      <div class="campinfo col-md-10">
      <CRow class="col-md-12">
        <label class="col-md-12">Campaign Name &nbsp;<span style="color:red">*</span></label>
        <CInput
          class="col-md-9"
          size="sm"
          id="input-33"
          v-model="mainObject.campname"
          type="text"
          required
          placeholder="Your Campaign Name"
          maxlength="100"
          :state="campname_valid"
        ></CInput>
      </CRow>
      <CRow class="col-md-12">
        <label class="col-md-12">
          <span class="link" @click="show_offer_details = true"><small class="text-white"><CIcon name="cil-info" /></small></span>
          <span class="ml-2">Select an offer &nbsp;<span style="color:red">*</span></span>
        <span class="link create-offer" @click="OpenOfferModal('create')"> or Create</span> 
        <span v-if="mainObject.offerid" @click="OpenOfferModal('edit')" class="ml-2 link">Edit</span>
        
      </label>
        <CSelect
          class="col-md-9"
          :options="offer_opt"
          :value.sync="mainObject.offerid"
          size="sm"
          placeholder="select"
          @change="getOffer()"
        />
      </CRow>

      <CRow class="col-md-12">
        <label class="col-md-12">
          <span class="link" @click="show_ts_details = true"><small class="text-white"><CIcon name="cil-info" /></small></span>
          <span class="ml-2">Select a traffic source &nbsp;<span style="color:red">*</span></span>
          <span class="link traffic-source" @click="OpenTsModal('create')"> or Create </span>
          <span v-if="mainObject.tsid" class="ml-2 link" @click="OpenTsModal('edit')">Edit</span>
        </label>
        <CSelect
          class="col-md-9"
          :options="ts_opt"
          :value.sync="mainObject.tsid"
          size="sm"
          placeholder="select"
          @change="getTrafficSource()"
        />
      </CRow>
      <CRow class="col-md-12">
        <label class="ml-3">Daily capping : </label>
        <CInput
          class="ml-2"
          size="sm"
          type="number"
          v-model="mainObject.capping"
          placeholder="0"
          required
          value="99999"
          :state="validateNumber"
        ></CInput>
      </CRow>
      <CRow class="col-md-12">
        <label class="ml-3">Payout tracking :</label>
        <CButtonGroup class="ml-2">
          <CInputRadio
            @change="check('0')"
            class=""
            name="payouttracking"
            id="payouttracking0"
            value="0"
            @input="mainObject.paymenttrack = $event.target.value"
            label="Don't Track"
          >
          </CInputRadio>
          <CInputRadio
            @change="check('1')"
            class="ml-3"
            name="payouttracking"
            id="payouttracking1"
            value="1"
            @input="mainObject.paymenttrack = $event.target.value"
            label="Fixed"
          >
          </CInputRadio>

          <CInputRadio
            @change="check('2')"
            class="ml-3"
            name="payouttracking"
            id="payouttracking2"
            value="2"
            @input="mainObject.paymenttrack = $event.target.value"
            label="Percentage of revenues"
          >
          </CInputRadio>
        </CButtonGroup>
        <div v-if="show_box" class="col-md-2" size="sm">
          <CInput
            append="$"
            size="sm"
            id="revenuevalue"
            v-model="mainObject.paymenttrack_value"
            type="number"
            required
            placeholder="0.5"
            v-on:keyup="revCheck()"
            :state="validateNumber"
          ></CInput>
        </div>

        <div v-if="show_per_box" class="col-md-2" size="sm">
          <CInput
            append="%"
            size="sm"
            id="revenuepervalue"
            v-model="mainObject.revenue_percent_value"
            type="number"
            required
            placeholder="0.5"
            :state="validateNumber"
          ></CInput>
        </div>
      </CRow>
      <CRow class="col-md-12 mt-2">
        <label class="ml-3"><CIcon name="cil-info" title="Chameleon feature automatically changes the current page theme to match the previous one for a cohesive experience." /><span class="ml-2">Chameleon :</span></label>
            <CSwitch
              class="ml-2"
              color="success"
              :checked="chameleon_enable"
              shape="pill"
              @update:checked="chameleonSwitch"
            />
            <CSelect
              v-show="mainObject.chameleon"
              class="ml-2 col-md-3"
              :options="chameleon_opt"
              :value.sync="chameleonSub"
              size="sm"
              placeholder="select"
            />
          </CRow>
    </div>
    </CRow>
    

    <CCard color="dark" class="col-md-12 mt-3">
      <CRow>
        <div class="c-avatar ml-2">
          <img src="icon/camp/campaign-icon-2.png" height="30" />
        </div>
        <h3 class="color-light text-white ml-2">Security Section</h3>
        <img
          v-if="security_show == false"
          @click="check('security_open'), showTxt('security')"
          class="ml-3 mt-2"
          src="icon/plus.png"
          height="20"
        />
        <img
          v-if="security_show == true"
          @click="check('security_close'), showTxt('security')"
          class="ml-3 mt-2"
          src="icon/minus.png"
          height="20"
        />
      </CRow>
    </CCard>
    <Transition name="fade">
    <div class="col-md-12" v-show="security_show">
      <div class="security-section">
      <master-security
            :mainObject="mainObject" :security_show="security_show"></master-security>
          </div>
    </div>
    </Transition>
    <CCard color="dark" class="col-md-12">
      <CRow>
        <div class="c-avatar ml-2">
          <img src="icon/camp/campaign-icon-3.png" height="30" />
        </div>
        <h3 class="color-light text-white ml-2">Fallback Section</h3>
        <img
          v-if="fallback_show == false"
          @click="check('fallback_open')"
          class="ml-3 mt-2"
          src="icon/plus.png"
          height="20"
        />
        <img
          v-if="fallback_show == true"
          @click="check('fallback_close')"
          class="ml-2 mt-2"
          src="icon/minus.png"
          height="20"
        />
      </CRow>
    </CCard>
    <Transition name="fade">
    <div v-if="fallback_show == true" class="col-md-12 p-2">
      <CRow class="col-md-12">
        <CCol class="col-md-3"></CCol>
        <CCol>
          <label>
          <small class="ml-2 link" title="Show Macros" @click="collapse = !collapse">Add Macros</small>
            <CCollapse :show="collapse" class="">
          <CRow class="link" v-if="show_macro" >
            <small v-for="(value, index) in macros" style="background:#e6e6e6;">
            <label class="p-1" @click="addMacrosconv(value)">{{value}}</label>
            </small>
          </CRow>
          </CCollapse>
          </label>
        </CCol>
         
          </CRow>
      <div class="fallback-section">
      <CRow class="mt-2 col-md-12">
        <label class="col-md-3">
          Blocked Traffic Fallback URL
          <i>optional &nbsp;</i><br>
          <span class="link" @click="CopyOfferUrl()">( Same as Offer URL )</span>
        </label>
        <CInput
          class="col-md-8 url_text"
          size="sm"
          id="BlockedTrafficFallbackURL"
          v-model="mainObject.BlockedTrafficFallbackURL"
          @input="checkButton(mainObject.BlockedTrafficFallbackURL,'url')"
          type="text"
          required
          placeholder="Fallback URL for Block traffic"
          @click="showTxt('block_traffic')"
          maxlength="2000"
        ></CInput>

        <label class="col-md-3">
          Discarded Traffic Fallback URL
          <i>optional</i>
        </label>
        <CInput
          v-if="cpurl"
          class="col-md-8 url_text"
          size="sm"
          id="DiscardedTrafficFallbackURL"
          v-model="mainObject.DiscardedTrafficFallbackURL"
          type="text"
          required
          placeholder="Fallback URL for Discarded traffic"
          @click="showTxt('discarded_traffic')"
          maxlength="2000"
        ></CInput>
        <label class="col-md-10">
          <span class="link" @click="CopyUrl()">( Same as Blocked Traffic Fallback URL )</span>
        </label>
      </CRow>
    </div>
    </div>
    </Transition>
    <CCard color="dark" class="col-md-12">
      <CRow>
        <div class="c-avatar ml-2">
          <img src="icon/camp/campaign-icon-4.png" height="30" />
        </div>
        <h3 class="ml-2 color-light text-white">Advance Section</h3>
        <img
          v-if="advance_show == false"
          @click="check('advance_open')"
          class="ml-2 mt-2"
          src="icon/plus.png"
          height="20"
        />
        <img
          v-if="advance_show == true"
          @click="check('advance_close')"
          class="ml-2 mt-2"
          src="icon/minus.png"
          height="20"
        />
      </CRow>
    </CCard>
    <Transition name="fade">
    <div v-if="advance_show == true" class="col-md-12 p-2">
      <div class="advance-section">
      <CRow class="mt-2 col-md-12">
        <label class="col-md-3">
          Frequency Capping
          <i>optional</i>
        </label>
        <CRow class="col-md-8" @click="showTxt('frequency_capping')">
          <CInputCheckbox
            :checked.sync="mainObject.frequencyConfig.ip"
            value="true"
            unchecked-value="false"
            @change="check('show_ip=true')"
            label="IP"
            id="ip"
          >
          </CInputCheckbox>
          <CInput
            class="col-md-4"
            v-if="show_ip"
            size="sm"
            id="ip_value"
            v-model="mainObject.frequencyConfig.ip_value"
            type="number"
            min="1"
            required
            placeholder="ip-ထ"
            @input="checkMinValue($event, 'ip_value')"
          ></CInput>
          <CInputCheckbox
            class="ml-2"
            :checked.sync="mainObject.frequencyConfig.ipfp"
            value="true"
            unchecked-value="false"
            @change="check('show_ipfp=true')"
            label="IP (+) Fingerprint"
            id="ipfp"
          >
          </CInputCheckbox>
          <CInput
            class="col-md-4"
            v-if="show_ipfp"
            size="sm"
            id="ipfp_value"
            v-model="mainObject.frequencyConfig.ipfp_value"
            type="number"
            min="1"
            required
            placeholder="ip-fp-ထ"
            @input="checkMinValue($event, 'ipfp_value')"
          ></CInput>
          <CBadge class="ml-3 mb-5" color="danger" v-show="min_value">
            Please Enter minimum value is 1</CBadge
          >
        </CRow>
      </CRow>

      <CRow
        class="col-md-12"
        v-if="advance_show == true"
        @click="showTxt('custom_cr')"
      >
        <label class="col-md-3">
          Custom CR Threshold
          <i>optional</i>
        </label>
        <CInputCheckbox
          :checked.sync="mainObject.customCR.Enable"
          value="true"
          unchecked-value="false"
          @change="check('cust_cr=true')"
          label="Enable Custom CR Threshold"
          id="cust_cr"
        ></CInputCheckbox>
      </CRow>
      <CRow v-if="cust_cr">
        <label class="col-md-3"></label>
        <CCol class="col-md-2">
          <label>Suspicious</label>
          <CInput
            append="%"
            size="sm"
            id="suspicious"
            v-model="mainObject.customCR.suspicious"
            type="number"
            required
            placeholder="ထ"
          ></CInput>
        </CCol>
        <CCol class="col-md-2">
          <label>Dangerous</label>
          <CInput
            append="%"
            size="sm"
            id="dangerous"
            v-model="mainObject.customCR.dangerous"
            type="number"
            required
            placeholder="ထ"
          ></CInput>
        </CCol>
      </CRow>
      <!-- <CRow class="col-md-12 pb-3" v-if="advance_show == true" @click="showTxt('post_conversion')">
              <label class="col-md-3">
                Post Conversions Events
                <i>optional</i>
              </label>
              <CInputCheckbox
                :checked.sync="mainObject.network_type"
                value="true"
                unchecked-value="false"
                label="Enable post conversions events"
              >
              </CInputCheckbox>
            </CRow> -->
            </div>
    </div>
    </Transition>
    <CCard color="dark" class="col-md-12">
      <CRow>
        <div class="c-avatar ml-2">
          <img src="icon/camp/macro-icon.png" height="30" />
        </div>
        <h3 class="ml-2 color-light text-white">Macro Blocker</h3>
        <img
          v-if="macroblock_show == false"
          @click="check('macroblock_open')"
          class="ml-2 mt-2"
          src="icon/plus.png"
          height="20"
        />
        <img
          v-if="macroblock_show == true"
          @click="check('macroblock_close')"
          class="ml-2 mt-2"
          src="icon/minus.png"
          height="20"
        />
      </CRow>
    </CCard>

    <Transition name="fade">
    <div v-if="macroblock_show == true" class="col-md-12">
    <CRow class="col-md-12 ml-2">
      <CCol>
        <CRow>
        <b>Turn ON/OFF MacroBlocker Security</b>
            <CSwitch
              class="ml-3"
              color="success"
              :checked="enable"
              shape="pill"
              @update:checked="macroSwitch"
            />
        </CRow>
      </CCol>
      <CCol class="text-right">
        <span class="link ml-3" @click="DownloadSampleMacroCsv()">Download Sample CSV file</span>
        <small>
          <div><span class="text-danger">(Note: Maximum CSV row value length is 50) </span></div>
          <div><span class="text-danger">(Note: CSV data will be updated after 30 minutes) </span></div>
        </small>
      </CCol>
    </CRow>
    <Transition name="fade">
    <CCard v-show="enable" class="col-md-12 p-4 mt-3" style="background:#c9c9c940">
      <!-- Macro_1 -->
          <CRow class="col-md-12">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="false"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro1"
                @input="macro_val_1 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              
             <div v-if="ismacroCsv[macro.macro1]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro1]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro1" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro1)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_1"
                v-if="macroshow.macro1"
                :taggable="true"
                @tag="addTag1"
                class="filter1"
              >
              </multiselect>
              <input
                v-else
                type="file"
                class="form-control custom_file"
                id="files" 
                accept=".csv" 
                ref="file" @change="handleFileUpload(macro.macro1)" />
                <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro1 = !macroshow.macro1">{{ macroshow.macro1?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>
             </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="Macrofields.macro_1 == false"
            >
              <span @click="Clone('add', 1)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 1)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

        <!-- Macro_2 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_1">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro2"
                @input="macro_val_2 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro2]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro2]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro2" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro2)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_2"
                :taggable="true"
                @tag="addTag2"
                class="filter1"
                v-if="macroshow.macro2"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv" 
                class="form-control custom_file"
                ref="file" @change="handleFileUpload(macro.macro2)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro2 = !macroshow.macro2">{{ macroshow.macro2?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>            
            </div>  
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_2 == false"
            >
              <span @click="Clone('add', 2)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 2)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

          <!-- Macro_3 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_2">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro3"
                @input="macro_val_3 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro3]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro3]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro3" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro3)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_3"
                :taggable="true"
                @tag="addTag3"
                class="filter1"
                v-if="macroshow.macro3"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro3)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro3 = !macroshow.macro3">{{ macroshow.macro3?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge> 
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_3 == false"
            >
              <span @click="Clone('add', 3)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 3)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>


          <!-- Macro_4 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_3">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro4"
                @input="macro_val_4 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro4]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro4]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro4" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro4)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_4"
                :taggable="true"
                @tag="addTag4"
                class="filter1"
                v-if="macroshow.macro4"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro4)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro4 = !macroshow.macro4">{{ macroshow.macro4?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>  
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_4 == false"
            >
              <span @click="Clone('add', 4)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 4)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

          <!-- Macro_5 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_4">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro5"
                @input="macro_val_5 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro5]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro5]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro5" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro5)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_5"
                :taggable="true"
                @tag="addTag5"
                class="filter1"
                v-if="macroshow.macro5"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro5)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro5 = !macroshow.macro5">{{ macroshow.macro5?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_5 == false"
            >
              <span @click="Clone('add', 5)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 5)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>
        <!-- Macro_6 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_5">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro6"
                @input="macro_val_6 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro6]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro6]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro6" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro6)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_6"
                :taggable="true"
                @tag="addTag6"
                class="filter1"
                v-if="macroshow.macro6"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro6)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro6 = !macroshow.macro6">{{ macroshow.macro6?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_6 == false"
            >
              <span @click="Clone('add', 6)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 6)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

          <!-- Macro_7 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_6">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro7"
                @input="macro_val_7 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro7]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro7]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro7" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro7)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_7"
                :taggable="true"
                @tag="addTag7"
                class="filter1"
                v-if="macroshow.macro7"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro7)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro7 = !macroshow.macro7">{{ macroshow.macro7?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_7 == false"
            >
              <span @click="Clone('add', 7)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 7)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

         <!-- Macro_8 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_7">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro8"
                @input="macro_val_8 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro8]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro8]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro8" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro8)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_8"
                :taggable="true"
                @tag="addTag8"
                class="filter1"
                v-if="macroshow.macro8"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro8)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro8 = !macroshow.macro8">{{ macroshow.macro8?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_8 == false"
            >
              <span @click="Clone('add', 8)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 8)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

        <!-- Macro_9 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_8">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro9"
                @input="macro_val_9 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro9]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro9]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro9" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro9)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_9"
                :taggable="true"
                @tag="addTag9"
                class="filter1"
                v-if="macroshow.macro9"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro9)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro9 = !macroshow.macro9">{{ macroshow.macro9?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>          
            </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_9 == false"
            >
              <span @click="Clone('add', 9)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 9)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

          <!-- Macro_10 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_9">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro10"
                @input="macro_val_10 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro10]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro10]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro10" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro10)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_10"
                :taggable="true"
                @tag="addTag10"
                class="filter1"
                v-if="macroshow.macro10"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro10)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro10 = !macroshow.macro10">{{ macroshow.macro10?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>
              </div>
            </CCol>
            <CCol
              class="col-md-1 mt-2"
              v-show="this.Macrofields.macro_10 == false"
            >
              <span @click="Clone('add', 10)">
                <CImg
                  src="img/logo/plus_icon.png"
                  title="add"
                  style="cursor: pointer"
                ></CImg>
              </span>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 10)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

        <!-- Macro_11 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_10">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro11"
                @input="macro_val_11 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro11]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro11]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro11" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro11)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Select"
                v-model="macro_val_11"
                :taggable="true"
                @tag="addTag11"
                class="filter1"
                v-if="macroshow.macro11"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro11)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro11 = !macroshow.macro11">{{ macroshow.macro11?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>
            </div>
            </CCol>
              <CCol
                class="col-md-1 mt-2"
                v-show="this.Macrofields.macro_11 == false"
              >
                <span @click="Clone('add', 11)">
                  <CImg
                    src="img/logo/plus_icon.png"
                    title="add"
                    style="cursor: pointer"
                  ></CImg>
                </span>
              </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 11)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>

        <!-- Macro_12 -->
        <CRow class="col-md-12 mt-2" v-show="Macrofields.macro_11">
            <CCol class="col-md-2 col-12">
              <multiselect
                :limit="2"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="false"
                :options="macro_opt"
                :preselect-first="false"
                :preserve-search="false"
                placeholder="Select"
                v-model="macro.macro12"
                @input="macro_val_12 = []"
                @open = "CheckDuplicateMacroOpt()"
                class="filter1"
              ></multiselect>
            </CCol>
            <CCol class="col-md-1  text-center">
                  <span style="font-size: 1.2rem"><strong>=</strong></span>
            </CCol>
            <CCol class="col-md-4 col-12">
              <div v-if="ismacroCsv[macro.macro12]" class="mt-2 font-weight-bold">
              {{ macroCsvname[macro.macro12]}}&nbsp;<a :href="csv_download_url+'campid='+mainObject._id+'&sub='+macro.macro12" target="_blank" rel="noopener noreferrer"> Download </a> 
              &nbsp; | &nbsp; <span class="text-danger" style="cursor: pointer;" @click="DeleteMacroCsv(mainObject._id, macro.macro12)">Delete</span>
             </div>
              <div v-else>
              <multiselect
                :limit="4"
                :clear-on-select="true"
                :close-on-select="true"
                :multiple="true"
                :options="[]"
                :preselect-first="false"
                :preserve-search="true"
                placeholder="Pick some"
                v-model="macro_val_12"
                :taggable="true"
                @tag="addTag12"
                class="filter1"
                v-if="macroshow.macro12"
              >
              </multiselect>
              <input type="file"
                id="files" 
                accept=".csv"
                class="form-control custom_file" 
                ref="file" @change="handleFileUpload(macro.macro12)" v-else />
              <CBadge class="mt-2 cursor-pointer" color="primary" @click="macroshow.macro12 = !macroshow.macro12">{{ macroshow.macro12?'Click here to Upload CSV':'Click here to Enter Manually' }}</CBadge>
            </div>
            </CCol>
            <CCol class="col-md-1 mt-2">
                  <span @click="Clone('remove', 12)">
                    <CImg
                      src="img/logo/minus_icon.png"
                      title="delete"
                      style="cursor: pointer"
                    ></CImg>
                  </span>
             </CCol>
          </CRow>
    </CCard>
    </Transition>
     </div>
    </Transition>

    <!-- Show Model -->
    <CModal
      title="Campain info"
      :show.sync="show_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
      <campaign-info></campaign-info>
    <template #footer>
        <CButton @click="show_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    </CCard>
    <CRow class="mt-3 mb-5">
      <CCol class="col-md-6 col-12 text-left">
        <div class="submit-campaign">
        <CButton :disabled="csv_uploading" type="submit" v-on:click="onSubmit" variant="" color="success">{{
          edit ? "Save" : "Submit"
        }}</CButton>
        <CButton v-if="csv_uploading" disabled>
          <CSpinner component="span" size="sm" aria-hidden="true"/>
         Please wait CSV file Uploading...
        </CButton>
      </div>
      </CCol>
      <!-- <b-button class="ml-2" v-on:click="onReset" type="reset" style="background-color:#ff374c">Reset</b-button> -->
    </CRow>

    <!-- Traffic Source Edit Modal Start  -->
    <CModal
        title="TrafficSource"
        :show.sync="ts_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
        
      >
      <div v-if="editTs==true">
        <ts-modal :edit_tsdata="edit_tsdata" :ts_modal="ts_modal" @tsmodal="closeModal"></ts-modal>
      </div>
      <div v-else>
        <ts-modal :ts_modal="ts_modal" @tsmodal="closeModal"></ts-modal>
      </div>
      
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
      <!-- Traffic Source Edit Modal End  -->

      <!-- Offer Edit Modal Start   -->
      <CModal
        title="Offer"
        :show.sync="offer_modal"
        :centered="true"
        scrollable
        color="dark"
        size="xl"
      >
      <div v-if="editOffer==true">
        <offer-modal :edit_offerdata="edit_offerdata" :offer_modal="offer_modal" @offermodal="closeModal"></offer-modal>
      </div>
      <div v-else>
        <offer-modal :offer_modal="offer_modal" @offermodal="closeModal"></offer-modal>
      </div>
      <template #footer>
        <CButton @click="closeModal()" color="danger" style="display: none;">Close</CButton>
      </template>
      </CModal>
      <!-- Offer Edit Modal End   -->

      <!-- Show offer info Model -->
    <CModal
      title="Offer Details"
      :show.sync="show_offer_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
      <offer-info></offer-info>
        <template #footer>
        <CButton @click="show_offer_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    <!-- End offer details modal  -->

    <!-- start ts details modal -->
     <!-- Show Model -->
     <CModal
      title="Traffic Source"
      :show.sync="show_ts_details"
      size="lg"
      :centered="true"
      scrollable
      color="dark"
    >
      <ts-info></ts-info>
      <template #footer>
        <CButton @click="show_ts_details = false" color="danger">Close</CButton>
        <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
      </template>
    </CModal>
    <!-- End ts details modal  -->

  
    <vue-tour :name="'campaign'"></vue-tour>
  </div>
</template>

<script>
// import VueTagsInput from '@johmun/vue-tags-input';
import CampaignService from "../../services/campaignControlService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Multiselect from "vue-multiselect";
import MasterSecurity from "./../../components/setting/MasterSecurity";
import "sweetalert2/src/sweetalert2.scss";
import VueTour from "../../components/vueTour.vue"
import CampaignInfo from './CampaignInfo.vue'
import TsModal from '../ts/NewTrafficSource.vue'
import TsInfo from '../ts/TsInfo.vue'
import OfferModal from '../offer/NewOffer.vue'
import OfferInfo from '../offer/OfferInfo.vue'
import OfferService from './../../services/offerControlService'
import TsService from "../../services/trafficsourceControllservice";
import HelperFunction from "./../../helper/helperFunction";
import Config from "../../services/config";
import sampleMacroCsvData from "../../services/sampleMacroCsvData"
import { CSelect } from "@coreui/vue";
export default {
  name: "NewCampaign",
  props:{
    edit_campaigndata:Object,
    campaign_modal:Boolean,
  },
  components: {
    MasterSecurity, Multiselect, VueTour, CampaignInfo, TsModal, OfferModal, OfferInfo, TsInfo
  },
  data() {
    return {
      chameleon_enable:false,
      chameleon_opt:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12'],
      chameleonSub:'',
      useremail:'',
      editTs:false,
      editOffer:false,
      edit_offerdata:{},
      edit_tsdata:{},
      ts_modal:false,
      offer_modal:false,
      Macrofields:{macro_1 :false,macro_2:false,macro_3:false,macro_4:false,macro_5:false,macro_6:false,macro_7:false,macro_8:false,macro_9:false,macro_10:false,macro_11:false,macro_12:false},
      macro:{macro1:'',macro2:'',macro3:'',macro4:'',macro5:'',macro6:'',macro7:'',macro8:'',macro9:'',macro10:'',macro11:'',macro12:''},
      macro_val_1 :[],
      macro_val_2 :[],
      macro_val_3 :[],
      macro_val_4 :[],
      macro_val_5 :[],
      macro_val_6 :[],
      macro_val_7 :[],
      macro_val_8 :[],
      macro_val_9 :[],
      macro_val_10 :[],
      macro_val_11 :[],
      macro_val_12 :[],
      macrosBlock:[],
      show_macro:true,
      macros:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id'],
      macro_opt:['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12'],
      collapse:false,
      min_value: false,
      show_details: false,
      show_offer_details:false,
      show_ts_details:false,
      offer_data: "",
      edit: false,
      show_box: false,
      show_per_box: false,
      security_show: false,
      fallback_show: true,
      advance_show: true,
      macroblock_show: true,
      show_ip: false,
      show_ipfp: false,
      cust_cr: false,
      data: "",
      user: "",
      user_status: false,
      ts_opt: [{ label: "Select One", value: null }],
      offer_opt: [{ label: "Select One", value: null }],
      mainObject: {
        campname: "",
        offerid: "",
        tsid: "",
        securityConfig: {
          proxyTraffic: {
            webProxy: false,
            vpnProxy: false,
            operaProxy: false,
            googleProxy: false,
          },
          adultKeyword: { adultkeyword: false },
          nonPlayStoreApk: { nonPlayStoreApk: false },
          susDevConvDist: { susDevConvDist: false },
          dangerIp: { attackIp: false, abuseIp: false, malwareIp: false },
          requestTempring: {
            invalidReq: false,
            headerInjection: false,
            invalidApp: false,
            macrosBlock:false
          },
          harmfulApp: {
            automatedSoftware: false,
            automatedSoftwarel2: false,
            automatedSoftwarel3: false,
            deviceAnomalies: false,
            waspaList: false,
            unseen : false
          },
          iframeTraffic: { regularIFrame: false, tinyIFrame: false },
          high_risk: false,
          suspicious: false,
          clickPage: { clickPage: false },
          forceDefaultBrowser:false
        },
        frequencyConfig: {},
        customCR: {},
        offername: "",
        DiscardedTrafficFallbackURL :"",
        BlockedTrafficFallbackURL : "",
        macrosBlock:{ sub1 :[],sub2 :[],sub3 :[],sub4 :[],sub5 :[],sub6 :[],sub7 :[],sub8 :[],sub9 :[],sub10 :[],sub11 :[],sub12 :[] },
        chameleon : false,
      },
      enable: false,
      value: [],
      options: [
        { name: "Vue.js", code: "vu" },
        { name: "Javascript", code: "js" },
        { name: "Open Source", code: "os" },
      ],
      foods: [
        { text: "Select One", value: null },
        "Carrots",
        "Beans",
        "Tomatoes",
        "Corn",
      ],
      campaign_txt: true,
      offer_txt: false,
      traffic_txt: false,
      payout_txt: false,
      campaign_capping_txt: false,
      security_txt: false,
      blocktraffic_txt: false,
      discardedtraffic_txt: false,
      frequency_cappin_txt: false,
      custom_cr_txt: false,
      post_conversion_txt: false,
      cpurl:true,
      macroshow:{macro1:true,macro2:true,macro3:true,macro4:true,macro5:true,macro6:true,macro7:true,macro8:true,macro9:true,macro10:true,macro11:true,macro12:true},
      csvId: "",
      ismacroCsv:{sub1:false,sub2:false,sub3:false,sub4:false,sub5:false,sub6:false,sub7:false,sub8:false,sub9:false,sub10:false,sub11:false,sub12:false},
      macroCsvname:{},
      csv_download_url: "",
      csv_uploading: false,
    };
  },
  async mounted() {
    if(window.localStorage.getItem('guide_tool') == 'true'){
        this.$tours['campaign'].start()
        var element = document.querySelector('.create-campaign');
        if(element){
          element.classList.remove('focus_guide');
        }
        var classes = ['.create-campaign','.sdk-menu','.report-menu','.campaign-list']
        classes.map((t)=> {
          var ele = document.querySelector(t);
          if(ele){ele.classList.remove('focus_guide');}
        })
        this.$tours['sidebar'].stop()
      }
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      if (window.localStorage.getItem("user") == "null") {
        alert("Please Enter User Name and Password");
        this.$router.push("Login");
      }else if(window.localStorage.getItem("user") == 'csp'){
          console.log('new camp')
          return;
        } else {
        this.user_status = window.localStorage.getItem("status");
        this.getTsOffer();

        if (this.$root.$data.edit == true) {
          for (let f in this.mainObject.securityConfig) {
            if (typeof this.mainObject.securityConfig[f] == "object") {
              for (let s in this.mainObject.securityConfig[f]) {
                this.mainObject.securityConfig[f][s] =
                  this.$root.$data.campaign.securityConfig[f][s] || false;
              }
            } else {
              this.mainObject.securityConfig[f] =
                this.$root.$data.campaign.securityConfig[f] || false;
            }
          }

          delete this.$root.$data.campaign.securityConfig;
          this.mainObject = {...this.mainObject, ...this.$root.$data.campaign};

          this.edit = true;
          var t = Object.keys(this.mainObject.macrosBlock)
          // if(this.mainObject.macrosBlock[t[0]] && this.mainObject.macrosBlock[t[0]].length != 0)this.Macrofields.macro_1 = true
          if(t[1])this.Macrofields.macro_1 = true
          if(t[2])this.Macrofields.macro_2 = true
          if(t[3])this.Macrofields.macro_3 = true
          if(t[4])this.Macrofields.macro_4 = true
          if(t[5])this.Macrofields.macro_5 = true
          if(t[6])this.Macrofields.macro_6 = true
          if(t[7])this.Macrofields.macro_7 = true
          if(t[8])this.Macrofields.macro_8 = true
          if(t[9])this.Macrofields.macro_9 = true
          if(t[10])this.Macrofields.macro_10 = true
          if(t[11])this.Macrofields.macro_11 = true
          this.macro.macro1=t[0]
          this.macro.macro2=t[1]
          this.macro.macro3=t[2]
          this.macro.macro4=t[3]
          this.macro.macro5=t[4]
          this.macro.macro6=t[5]
          this.macro.macro7=t[6]
          this.macro.macro8=t[7]
          this.macro.macro9=t[8]
          this.macro.macro10=t[9]
          this.macro.macro11=t[10]
          this.macro.macro12=t[11]
          this.macro_val_1 = this.mainObject.macrosBlock[t[0]]
          this.macro_val_2 = this.mainObject.macrosBlock[t[1]]
          this.macro_val_3 = this.mainObject.macrosBlock[t[2]]
          this.macro_val_4 = this.mainObject.macrosBlock[t[3]]
          this.macro_val_5 = this.mainObject.macrosBlock[t[4]]
          this.macro_val_6 = this.mainObject.macrosBlock[t[5]]
          this.macro_val_7 = this.mainObject.macrosBlock[t[6]]
          this.macro_val_8 = this.mainObject.macrosBlock[t[7]]
          this.macro_val_9 = this.mainObject.macrosBlock[t[8]]
          this.macro_val_10 = this.mainObject.macrosBlock[t[9]]
          this.macro_val_11 = this.mainObject.macrosBlock[t[10]]
          this.macro_val_12 = this.mainObject.macrosBlock[t[11]]
          this.CheckDuplicateMacroOpt()
          this.enable = this.mainObject.securityConfig.requestTempring.macrosBlock;
          this.macroblock_show  = this.enable    
          if (
            this.mainObject.capping == "99999" ||
            this.mainObject.capping == 99999
          )
            this.mainObject.capping = 0;
          this.$root.$data.edit = false;
          this.security_show = true;
          this.fallback_show = true;
          this.advance_show = true;
          this.checkBoxcheck();
          switch (this.mainObject.paymenttrack) {
            case 0:
              this.show_per_box = this.show_box = false;
              break;
            case 1:
              this.show_box = true;
              this.show_per_box = false;
              break;
            case 2:
              this.show_per_box = true;
              this.show_box = false;
              break;
          }
          if (this.mainObject.frequencyConfig.ip == true) {
            this.show_ip = true;
          }
          if (this.mainObject.frequencyConfig.ipfp == true) {
            this.show_ipfp = true;
          }
          if (this.mainObject.customCR.Enable == true) {
            this.cust_cr = true;
          }
        } else {
          this.CheckSecurity();
        }
      }
    }
    this.useremail = window.localStorage.getItem("email");
    if(this.campaign_modal == false){
      this.generateCsvId()
    }
    this.csv_download_url = Config.downloadMacroCsv
  },
  computed: {
    campname_valid() {
      if (this.mainObject.campname == "") {
        return false;
      }else if(HelperFunction.validateName(this.mainObject.campname,100) == false) {
        this.mainObject.campname = ''
        return false;
      }else{
        return true;
      }
    },
    validateNumber(){
      if(HelperFunction.validateNumber(this.mainObject.capping,0,999999999999) == false){
        this.mainObject.capping = 0
      }
      if(HelperFunction.validateNumber(this.mainObject.paymenttrack_value,0,999999999999) == false){
        this.mainObject.paymenttrack_value = 0
      }
      if(HelperFunction.validateNumber(this.mainObject.revenue_percent_value,0,100) == false){
        this.mainObject.revenue_percent_value = 0
      }
    }
  },
  methods: {
    chameleonSwitch() {
      if (this.chameleon_enable == true) {
        this.chameleon_enable = false;
      } else {
        this.chameleon_enable = true;
      }
      this.mainObject.chameleon = this.chameleon_enable
      
    },
    CheckDuplicateMacroOpt(){
      var opt = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12'];
      Object.values(this.macro).forEach(val => {
        opt = opt.filter(function(item) {
          return item !== val
      })
      });
      this.macro_opt  =  opt
    },
    async macroSwitch() {
        if (this.enable == true) {
          this.enable = false;
        } else {
          this.enable = true;
        }
        this.mainObject.securityConfig.requestTempring.macrosBlock = this.enable
    },
    Clone(txt,count){
      this.CheckDuplicateMacroOpt()
      if(txt == 'add'){ 
      switch(count){
        case 1 : this.Macrofields.macro_1  = true
        break;
        case 2 : this.Macrofields.macro_2 = true
        break;
        case 3 : this.Macrofields.macro_3 = true
        break;
        case 4 : this.Macrofields.macro_4 = true
        break;
        case 5 : this.Macrofields.macro_5 = true
        break;
        case 6 : this.Macrofields.macro_6 = true
        break;
        case 7 : this.Macrofields.macro_7 = true
        break;
        case 8 : this.Macrofields.macro_8 = true
        break;
        case 9 : this.Macrofields.macro_9 = true
        break;
        case 10 : this.Macrofields.macro_10 = true
        break;
        case 11 : this.Macrofields.macro_11 = true
        break;
      }
      }
      if(txt == 'remove'){
        switch(count){
        case 1 : 
          this.mainObject.macrosBlock.sub1 = [];
          this.macro_val_1 = [];
          this.macro.macro1 = [];
        break;
        case 2 : this.Macrofields.macro_1 = false;
        this.mainObject.macrosBlock.sub2 = [];
        this.macro_val_2 = [];
        this.macro.macro2 = [];
        break;
        case 3 : this.Macrofields.macro_2 = false;
        this.mainObject.macrosBlock.sub3 = [];
        this.macro_val_3 = [];
        this.macro.macro3 = [];
        break;
        case 4 : this.Macrofields.macro_3 = false;
        this.mainObject.macrosBlock.sub4 = [];
        this.macro_val_4 = [];
        this.macro.macro4 = [];
        break;
        case 5 : this.Macrofields.macro_4 = false;
        this.mainObject.macrosBlock.sub5 = [];
        this.macro_val_5 = [];
        this.macro.macro5 = [];
        break;
        case 6 : this.Macrofields.macro_5 = false;
        this.mainObject.macrosBlock.sub6 = []
        this.macro_val_6 = [];
        this.macro.macro6 = [];
        break;
        case 7 : this.Macrofields.macro_6 = false;
        this.mainObject.macrosBlock.sub7 = [];
        this.macro_val_7 = [];
        this.macro.macro7 = [];
        break;
        case 8 : this.Macrofields.macro_7 = false;
        this.mainObject.macrosBlock.sub8 = [];
        this.macro_val_8 = [];
        this.macro.macro8 = [];
        break;
        case 9 : this.Macrofields.macro_8 = false;
        this.mainObject.macrosBlock.sub9 = [];
        this.macro_val_9 = [];
        this.macro.macro9 = [];
        break;
        case 10 : this.Macrofields.macro_9 = false;
        this.mainObject.macrosBlock.sub10= [];
        this.macro_val_10 = [];
        this.macro.macro10 = [];
        break;
        case 11 : this.Macrofields.macro_10 = false;
        this.mainObject.macrosBlock.sub11 = [];
        this.macro_val_11 = [];
        this.macro.macro11 = [];
        break;
        case 12 : this.Macrofields.macro_11 = false;
        this.mainObject.macrosBlock.sub12 = [];
        this.macro_val_12 = [];
        this.macro.macro12 = [];
        break;
      }
      }
    },
    addTag1(newTag) { this.macro_val_1.push(newTag); },
    addTag2(newTag) { this.macro_val_2.push(newTag); },
    addTag3(newTag) { this.macro_val_3.push(newTag); },
    addTag4(newTag) { this.macro_val_4.push(newTag); },
    addTag5(newTag) { this.macro_val_5.push(newTag); },
    addTag6(newTag) { this.macro_val_6.push(newTag); },
    addTag7(newTag) { this.macro_val_7.push(newTag); },
    addTag8(newTag) { this.macro_val_8.push(newTag); },
    addTag9(newTag) { this.macro_val_9.push(newTag); },
    addTag10(newTag) { this.macro_val_10.push(newTag); },
    addTag11(newTag) { this.macro_val_11.push(newTag); },
    addTag12(newTag) { this.macro_val_12.push(newTag); },
    checkButton(url,txt){
      this. show_macro = false;
      var params = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12','rev','token','external_id']
      for(let e in params) {
        // match value from array and remove it
        if( url.match(new RegExp('{'+params[e]+'}')) ){
          delete params[e]
        }
      }
      switch(txt){
        case 'url':
          this.macros = params
          break;
        case 'purl':
          this.pmacros = params
          break;
      }
      this.show_macro = true; 
      },
    CopyOfferUrl(){
      if(this.mainObject.offerid == ""){
        alert("Please Select Offer");
        return false;
      }
      this.offer_data.map((r)=> {
        if(r.offerid == this.mainObject.offerid){
          this.mainObject.BlockedTrafficFallbackURL = r.offerurl;
          this.checkButton(this.mainObject.BlockedTrafficFallbackURL,'url')
          }
        else{ return ''; }
        })
        
    },
    CopyUrl(){
      this.cpurl = false;
      this.mainObject.DiscardedTrafficFallbackURL=this.mainObject.BlockedTrafficFallbackURL; 
      this.cpurl = true;
    },
    addMacrosconv(data){
        // var str1 = "?";
        // for (var i = 0; i < this.mainObject.BlockedTrafficFallbackURL.length; i++) {
        //   if (this.mainObject.BlockedTrafficFallbackURL.charAt(i) == "?") {
        //     var str1 = "&";
        //   }
        // }
      var str3 = "{" + data + "}";
      // let url_text = str1.concat(str3);
      let url_text = str3;
      this.mainObject.BlockedTrafficFallbackURL = this.mainObject.BlockedTrafficFallbackURL.concat(url_text)
      this.checkButton(this.mainObject.BlockedTrafficFallbackURL,'url')
      },
    checkMinValue(event, txt) {
      this.min_value = false;
      var number = parseInt(event);
      if (number == 0) {
        switch (txt) {
          case "ip_value":
            this.min_value = true;
            this.mainObject.frequencyConfig.ip_value = 1;
            break;
          case "ipfp_value":
            this.min_value = true;
            this.mainObject.frequencyConfig.ipfp_value = 1;
            break;
          default:
            this.min_value = false;
        }
      }
    },
    openModel() {
      this.show_details = true;
    },
    async CheckSecurity() {
      try {
        let response = await CampaignService.chksettings();

        if (response.message) {
          if (response.message.enable == true) {
            this.mainObject.securityConfig = response.message.securityConfig;
          }
        }
      } catch (e) {
        // console.log("connection error");
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Close'})
        // this.errorNotify("Error", "connection error", "danger");
      }
      this.security_show = true
    },
    async getTsOffer() {
      try {
        let ts = await CampaignService.gettsOffer().then((r) => {
          return r.message;
        });
        this.ts_opt = ts.map((t) => {
          return { label: t.tsname, value: t._id };
        });
        let offer = await CampaignService.getOffer().then((r) => {
          return r.message;
        });
        // console.log(offer)
        this.offer_data = this.offer_opt = offer.map((l) => {
          return {
            offerurl: l.offerurl,
            offername: l.offername,
            offerid: l._id,
            offer_rev: l.revenuevalue,
          };
        });
        this.offer_opt = offer.map((l) => {
          return { label: l.offername, value: l._id };
        });
      } catch (e) {
        console.log("connection error");
        // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Close'})
        // this.errorNotify('Error','connection error','danger')
        console.log(e);
      }
    },
    showTxt(txt) {
      this.campaign_txt = false;
      this.offer_txt = false;
      this.traffic_txt = false;
      this.payout_txt = false;
      this.campaign_capping_txt = false;
      this.security_txt = false;
      this.blocktraffic_txt = false;
      this.discardedtraffic_txt = false;
      this.frequency_cappin_txt = false;
      this.custom_cr_txt = false;
      this.post_conversion_txt = false;
      switch (txt) {
        case "campaign":
          this.campaign_txt = true;
          break;
        case "offer":
          this.offer_txt = true;
          break;
        case "traffic":
          this.traffic_txt = true;
          break;
        case "tracking":
          this.payout_txt = true;
          break;
        case "campaign_capping":
          this.campaign_capping_txt = true;
          break;
        case "security":
          this.security_txt = true;
          break;
        case "block_traffic":
          this.blocktraffic_txt = true;
          break;
        case "discarded_traffic":
          this.discardedtraffic_txt = true;
          break;
        case "frequency_capping":
          this.frequency_cappin_txt = true;
          break;
        case "custom_cr":
          this.custom_cr_txt = true;
          break;
        case "post_conversion":
          this.post_conversion_txt = true;
          break;
      }
    },
    check(evnt) {
      this.show_ip = false;
      this.show_box = false;
      this.show_ipfp = false;
      this.show_per_box = false;
      switch (evnt) {
        case "1":
          this.show_box = true;
          break;
        case "2":
          this.show_per_box = true;
          break;
        case "security_open":
          this.security_show = true;
          break;
        case "security_close":
          this.security_show = false;
          break;
        case "fallback_open":
          this.fallback_show = true;
          break;
        case "fallback_close":
          this.fallback_show = false;
          break;
        case "advance_open":
          this.advance_show = true;
          break;
        case "advance_close":
          this.advance_show = false;
          break;
        case "macroblock_open":
          this.macroblock_show = true;
        break;
        case "macroblock_close":
          this.macroblock_show = false;
          break;
        case "show_ip=true":
          this.mainObject.frequencyConfig.ipfp = false;
          this.min_value = false;
          this.show_ip = true;
          if (this.mainObject.frequencyConfig.ip == false) {
            this.show_ip = false;
          }
          break;
        case "show_ipfp=true":
          this.mainObject.frequencyConfig.ip = false;
          this.min_value = false;
          this.show_ipfp = true;
          if (this.mainObject.frequencyConfig.ipfp == false) {
            this.show_ipfp = false;
          }
          break;
        case "cust_cr=true":
          this.cust_cr = true;
          if (document.getElementById("cust_cr").checked == false) {
            this.cust_cr = false;
            this.mainObject.customCR.suspicious =
              this.mainObject.customCR.dangerous = 0;
          }
          break;
      }
    },
    CheckMcros(){
      this.mainObject.macrosBlock = {}
      this.mainObject.macrosBlock[this.macro.macro1]=this.macro_val_1;
      this.mainObject.macrosBlock[this.macro.macro2]=this.macro_val_2;
      this.mainObject.macrosBlock[this.macro.macro3]=this.macro_val_3;
      this.mainObject.macrosBlock[this.macro.macro4]=this.macro_val_4;
      this.mainObject.macrosBlock[this.macro.macro5]=this.macro_val_5;
      this.mainObject.macrosBlock[this.macro.macro6]=this.macro_val_6;
      this.mainObject.macrosBlock[this.macro.macro7]=this.macro_val_7;
      this.mainObject.macrosBlock[this.macro.macro8]=this.macro_val_8;
      this.mainObject.macrosBlock[this.macro.macro9]=this.macro_val_9;
      this.mainObject.macrosBlock[this.macro.macro10]=this.macro_val_10;
      this.mainObject.macrosBlock[this.macro.macro11]=this.macro_val_11;
      this.mainObject.macrosBlock[this.macro.macro12]=this.macro_val_12;
      delete this.mainObject.macrosBlock['']
      delete this.mainObject.macrosBlock['undefined']
    },
    async onSubmit() {
      try {
        if (this.Validate()) {
          this.CheckMcros()
          if (
            this.mainObject.campname == "" ||
            this.mainObject.offerid == "" ||
            this.mainObject.tsid == ""
          ) {
            Swal.fire({
              title: "Error!",
              text: "Please fill all required fields",
              icon: "error",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
            });
            //  this.errorNotify("Error", "Please field all required fields", "error");
          } else {
            if(this.mainObject.chameleonSub){delete this.mainObject.chameleonSub}
            if(this.mainObject.chameleon){
              this.mainObject.chameleonSub = this.chameleonSub
            }
            if (this.edit) {
              // console.log(this.edit +'true ')

              if (
                this.mainObject.capping == "0" ||
                this.mainObject.capping == null ||
                this.mainObject.capping == ""
              ) {
                this.mainObject.capping = "99999";
              }
              switch (this.mainObject.paymenttrack) {
                case 0:
                  this.mainObject.revenue_percent_value = "";
                  this.mainObject.paymenttrack_value = "";
                  break;
                case 1:
                  this.mainObject.revenue_percent_value = "";
                  break;
                case 2:
                  this.mainObject.paymenttrack_value = "";
                  break;
              }
              let updated_data = {
                campid: this.mainObject._id,
                changePayload: this.mainObject,
              };
              let response = await CampaignService.update(updated_data);

              if (response.result == true) {
                Swal.fire({
                  title: "Success!",
                  text: "data updated successfully",
                  icon: "success",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                // this.$router.push("/campaign/campaignlist");
              } else {
                Swal.fire({
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
                });
              }
              this.edit=false
              this.$emit('campaignmodal')
            } else {
              // console.log(this.edit +'false ')
              if (
                this.mainObject.capping == "0" ||
                this.mainObject.capping == null ||
                this.mainObject.capping == ""
              ) {
                this.mainObject.capping = "99999";
              }
              switch (this.mainObject.paymenttrack) {
                case 0:
                  this.mainObject.revenue_percent_value = "";
                  this.mainObject.paymenttrack_value = "";
                  break;
                case 1:
                  this.mainObject.revenue_percent_value = "";
                  break;
                case 2:
                  this.mainObject.paymenttrack_value = "";
                  break;
              }
              // console.log(JSON.stringify(this.mainObject))
              let response = await CampaignService.add(this.mainObject);
              //   .then(res => {
              //   if (res) {
              //     this.$emit('ready', true)
              //   }
              // });
              if (response.result == true) {
                Swal.fire({
                  title: "Success!",
                  text: "Campaign Saved!",
                  icon: "success",
                  confirmButtonText: "ok",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                if(window.localStorage.getItem('guide_tool') == 'true'){
                var el = document.querySelector('#next-btn');
                if(el){el.click() }
                }
                else{this.$router.push("/campaign/campaignlist");}
              } else {
                Swal.fire({
                  title: "Error!",
                  text: response.message,
                  icon: "error",
                  confirmButtonText: "ok",position: 'top-end',showConfirmButton: false,timer: 1000
                });
                // this.errorNotify("Error", "Campname is already exist!", "danger");
              }
            }
          }
        }
      } catch (e) {
        this.$emit("ready", false);
      }
    },
    Validate() {
      this.min_value = false;
      if (this.mainObject.frequencyConfig.ip) {
        this.mainObject.frequencyConfig.ipfp = false;
        this.mainObject.frequencyConfig.ipfp_value = 0;
        if (this.mainObject.frequencyConfig.ip_value >= 1) {
          return true;
        } else {
          this.min_value = true;
          Swal.fire({
            title: "Error!",
            text: "Ip value must be more then 0 !!",
            icon: "error",
            confirmButtonText: "ok",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          return false;
        }
      }
      if (this.mainObject.frequencyConfig.ipfp) {
        this.mainObject.frequencyConfig.ip = false;
        this.mainObject.frequencyConfig.ip_value = 0;
        if (this.mainObject.frequencyConfig.ipfp_value >= 1) {
          return true;
        } else {
          this.min_value = true;
          Swal.fire({
            title: "Error!",
            text: "IP (+) Fingerprint value must be more then 0 !!",
            icon: "error",
            confirmButtonText: "ok",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          return false;
        }
      }
      if(this.mainObject.BlockedTrafficFallbackURL){
          if(HelperFunction.validateUrl(this.mainObject.BlockedTrafficFallbackURL) == false){
            Swal.fire({title: 'Error',text: "Invalid Blocked Traffic Fallback URL",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
            return false;
          }
      }
      if(this.mainObject.DiscardedTrafficFallbackURL){
          if(HelperFunction.validateUrl(this.mainObject.DiscardedTrafficFallbackURL) == false){
            Swal.fire({title: 'Error',text: "Invalid Discarded Traffic Fallback URL",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
            return false;
          }
      }
      return true;
    },
    onReset() {
      swal("Are you sure you want to Reset?", {
        buttons: ["No!", "Confirm!"],
      }).then((value) => {
        if (value == true) {
          this.mainObject = [];
          this.mainObject.offer = null;
          this.mainObject.traffic_source = null;
        }
      });
    },
    logout() {
      swal("Are you sure you want to Logout?", {
        buttons: ["No!", "Yes!"],
      }).then((value) => {
        if (value == true) {
          window.localStorage.setItem("user", null);
          window.localStorage.setItem("status", null);
          this.$router.push("Login");
        }
      });
    },
    toFormData(obj) {
      var formData = new FormData();
      for (var key in obj) {
        formData.append(key, obj[key]);
      }
      // if(window.$cookies.get('type')){
      //   formData.append('user_type',window.$cookies.get('type'))
      // }
      return formData;
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    revCheck() {
      var val = document.getElementById("revenuevalue").value;
      let rev;
      this.offer_data.map((r) => {
        if (r.offerid == this.mainObject.offerid) {
          rev = r.offer_rev;
        }
      });
      if (this.mainObject.paymenttrack_value > rev) {
        Swal.fire({
          title: "Error!",
          text: "Can't enter value more than offer revenue",
          icon: "warning",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
        // this.errorNotify('Error',"Can't enter value more than offer revenue",'danger')
        this.mainObject.paymenttrack_value = "";
      }
      // else{
      //   console.log('true')
      // }

      // console.log(this.offer_data)
      // console.log(val)
    },
    checkBoxcheck() {
      switch (this.mainObject.paymenttrack) {
        case 0:
          document.getElementById("payouttracking0").checked = true;
          break;
        case 1:
          document.getElementById("payouttracking1").checked = true;
          break;
        case 2:
          document.getElementById("payouttracking2").checked = true;
          break;
      }
    },
    closeModal(){
        this.getTsOffer();
        this.ts_modal = false
        this.offer_modal = false
        this.getTrafficSource()
        this.getOffer()
    },
    async getOffer(){
      this.edit_offerdata = {}
      var data = { useremail: this.useremail, offerid: this.mainObject.offerid };
      let response = await OfferService.get(data);
      if(response.result){
        this.edit_offerdata = response.message[0];
        }
    },
    async getTrafficSource(){
      this.edit_tsdata = {};
      var data = { useremail: this.useremail, tsid: this.mainObject.tsid };
        let response = await TsService.tsget(data);
        if(response.result){
          this.edit_tsdata = response.message[0];
        }
    },
    OpenOfferModal(txt){
      this.editOffer = true
      if(txt == 'create'){
        this.editOffer = false
      }
      this.offer_modal = true
    },
    OpenTsModal(txt){
      this.editTs = true
      if(txt == 'create'){
        this.editTs = false
      }
      this.ts_modal = true
    },
   async generateCsvId(){
      let response = await CampaignService.genrateCsvid();
      if(response){
        this.csvId = response
        this.mainObject._id = this.csvId
      }
      else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
    },
    checkCsv(){
      var sub = ['sub1','sub2','sub3','sub4','sub5','sub6','sub7','sub8','sub9','sub10','sub11','sub12']
      sub.map((s)=>{
        var payload = 'campid='+this.mainObject._id+'&sub='+s
        CampaignService.checkMacroCsv(payload).then((response) => {
          if(response.result){
            this.ismacroCsv[s] = true
            if(response.data){
              this.macroCsvname[s]= response.data
            }
          }else{
            this.ismacroCsv[s] = false
          }
        })
      })
    },
   async DeleteMacroCsv(id,sub){
    if(confirm('Are you sure you want to delete this file?')){
      let payload = 'campid='+id+'&sub='+sub
      let response = await CampaignService.deleteMacroCsv(payload);
      if(response.result){
        this.ismacroCsv[sub] = false
        this.macroCsvname[sub] = ''
        Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
      else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
    }
    },
    handleFileUpload(macro) {
      if(macro && macro.length !=0){
      const formData = new FormData();
      if(this.$refs.file.files[0]){
        var file = this.$refs.file.files[0];
        const maxFileSize = 64 * 1024 * 1024;
        if(file.size > maxFileSize){
          Swal.fire({title: 'Error!',text: 'File size should be less than 64MB',icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
          return false;
        }
        formData.append('macroFile', file);
        this.uploadCSV(formData,macro);
      }
      }
      else{
        var file = this.$refs.file.files[0];
        this.$refs.file.value = '';
        Swal.fire({title: 'Error!',text: 'Please select macro',icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 2000})
      }
    },
    async uploadCSV(formData,macro) {
      this.csv_uploading = true
      let param = 'campid='+this.mainObject._id+'&sub='+macro
      let response = await CampaignService.addCsv(param,formData);
      if(response.result){
        this.checkCsv();
        Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
      else{
        Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
      }
      this.csv_uploading = false
    },
    DownloadSampleMacroCsv(){
      sampleMacroCsvData.downloadCSV(sampleMacroCsvData.sampleData)
    }
  },
  watch: {
    selected(newVal, oldVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.flavours.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
    campaign_modal(value){
      this.security_show = false
      this.chameleonSub = ''
      this.chameleon_enable = false
        if(value == true){
          this.security_show = true
          this.edit = true;
          Object.assign(this.mainObject, this.edit_campaigndata)
          if(this.mainObject.chameleon){
            this.chameleonSub= this.mainObject.chameleonSub
            this.chameleon_enable = this.mainObject.chameleon
          }
          var t = Object.keys(this.mainObject.macrosBlock)
          // if(this.mainObject.macrosBlock[t[0]] && this.mainObject.macrosBlock[t[0]].length != 0)this.Macrofields.macro_1 = true
          if(t[1])this.Macrofields.macro_1 = true
          if(t[2])this.Macrofields.macro_2 = true
          if(t[3])this.Macrofields.macro_3 = true
          if(t[4])this.Macrofields.macro_4 = true
          if(t[5])this.Macrofields.macro_5 = true
          if(t[6])this.Macrofields.macro_6 = true
          if(t[7])this.Macrofields.macro_7 = true
          if(t[8])this.Macrofields.macro_8 = true
          if(t[9])this.Macrofields.macro_9 = true
          if(t[10])this.Macrofields.macro_10 = true
          if(t[11])this.Macrofields.macro_11 = true
          this.macro.macro1=t[0]
          this.macro.macro2=t[1]
          this.macro.macro3=t[2]
          this.macro.macro4=t[3]
          this.macro.macro5=t[4]
          this.macro.macro6=t[5]
          this.macro.macro7=t[6]
          this.macro.macro8=t[7]
          this.macro.macro9=t[8]
          this.macro.macro10=t[9]
          this.macro.macro11=t[10]
          this.macro.macro12=t[11]
          this.macro_val_1 = this.mainObject.macrosBlock[t[0]]
          this.macro_val_2 = this.mainObject.macrosBlock[t[1]]
          this.macro_val_3 = this.mainObject.macrosBlock[t[2]]
          this.macro_val_4 = this.mainObject.macrosBlock[t[3]]
          this.macro_val_5 = this.mainObject.macrosBlock[t[4]]
          this.macro_val_6 = this.mainObject.macrosBlock[t[5]]
          this.macro_val_7 = this.mainObject.macrosBlock[t[6]]
          this.macro_val_8 = this.mainObject.macrosBlock[t[7]]
          this.macro_val_9 = this.mainObject.macrosBlock[t[8]]
          this.macro_val_10 = this.mainObject.macrosBlock[t[9]]
          this.macro_val_11 = this.mainObject.macrosBlock[t[10]]
          this.macro_val_12 = this.mainObject.macrosBlock[t[11]]
          this.CheckDuplicateMacroOpt()
          this.enable = this.mainObject.securityConfig.requestTempring.macrosBlock;
          this.macroblock_show  = this.enable    
          if (
            this.mainObject.capping == "99999" ||
            this.mainObject.capping == 99999
          )
            this.mainObject.capping = 0;
          this.$root.$data.edit = false;
          this.security_show = true;
          this.fallback_show = true;
          this.advance_show = true;
          this.checkBoxcheck();
          switch (this.mainObject.paymenttrack) {
            case 0:
              this.show_per_box = this.show_box = false;
              break;
            case 1:
              this.show_box = true;
              this.show_per_box = false;
              break;
            case 2:
              this.show_per_box = true;
              this.show_box = false;
              break;
          }
          if (this.mainObject.frequencyConfig.ip == true) {
            this.show_ip = true;
          }
          if (this.mainObject.frequencyConfig.ipfp == true) {
            this.show_ipfp = true;
          }
          if (this.mainObject.customCR.Enable == true) {
            this.cust_cr = true;
          }
          this.getOffer();
          this.getTrafficSource();
          this.checkCsv();
        }
      }
  },
};
</script>

<style scoped>
.url_text{
  font-family: 'Oswald', sans-serif;
  color: #000;
  font-weight: 600;
}
.link{
  color: blue;
  cursor: pointer;
}
.label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
  text-decoration: underline;
}
.security_img {
  height: auto;
}
.border_select {
  /* border:2px solid rgb(0, 17, 255);
  padding: 2px; */
}
.border_notselect {
  /* border:none;
  border:1px solid rgb(204, 204, 204);
  padding: 2px; */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
.custom_file{
  padding: 4px !important;
  background: beige;
}
</style>
<style>
.cursor-pointer{
  cursor: pointer;
}
</style>
