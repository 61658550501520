export default{
    sampleDatafields:['title'],
    sampleData:[
        {'title':'sample_data_1'},
        {'title':'sample_data_2'},
        {'title':'sample_data_3'},
        {'title':'sample_data_4'},
        {'title':'sample_data_5'},
    ],
    downloadCSV(data) {
        const rows = [];
        const headers = Object.keys(data[0]);
      
        // Add headers to CSV
        rows.push(headers.join(','));
      
        // Add data to CSV
        data.forEach((item) => {
          const values = headers.map((header) => {
            const value = item[header];
            return JSON.stringify(value);
          });
          rows.push(values.join(','));
        });
      
        // Convert to CSV and download
        const csv = rows.join('\n');
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'macro_csv_sample.csv';
        link.click();
      }
}